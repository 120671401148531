import Vue from 'vue';
import Vuex from 'vuex';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    endpoint: process.env.VUE_APP_ENDPOINT,
    endpoint2: process.env.VUE_APP_ENDPOINT2,
    isSupplier: false,
    isBuyer: true,
    isBuyerDefault: false,
    // notification: {
    //   type: 0,
    //   message: "",
    // },
    user: null,
    token: '',
    loader: false,
    cart: {
      jumia: [],
      konga: [],
      xerox: [],
    },
    //this is for whenever we call get bsmpCart from localstorage and there is nothing there, set this as the default cart item
    defaultCart: {
      jumia: [],
      konga: [],
      xerox: [],
    },
    defaultSearchParam: 'office accessories',
    demoURL: process.env.VUE_APP_DEMO_URL,
    filterQuery: null,
  },
  getters,
  mutations,
  actions,
});

export default store;
